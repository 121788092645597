import axios from 'axios';

const axiosSMS = axios.create({
  baseURL: 'https://portal-otp.smsmkt.com/api/otp-send',
});

axiosSMS.interceptors.request.use(
  (config) => {
    config.headers.common['Access-Control-Allow-Origin'] = "*";
    config.headers.common['api_key'] = '6530872881f77ab91c0eff3e92be3dcb';
    config.headers.common['secret_key'] = 'ec1gCIQiR3CNwgtb';
    // config.headers.common['Access-Control-Allow-Headers'] = 'Content-Type';
    // config.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
    // config.headers.common['Access-Control-Allow-Credentials'] = true;
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosSMS;