import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import Select from "react-select";
import axios from "services/axios";
import { useToasts } from "react-toast-notifications";
import ProfilePictureUC from "components/ProfilePictureUC";
/* Service */
import useWindowDimensions from "services/useWindowDimensions";
import ValidateService from "services/validateValue";
import * as Storage from "../../../services/Storage.service";
import { styleSelect } from "assets/styles/theme/ReactSelect.js";
import useMenu from "services/useMenu";
import ConfirmEdit from "components/ConfirmDialog/ConfirmEdit";
import { useDispatch } from "react-redux";
import { fetchLoading, fetchSuccess } from "redux/actions/common";
import FilesService from "services/files";
import "antd/dist/antd.css";
import { Radio } from "antd";
import config from "@services/helpers";
import validator from "validator";
// import service from 'undefined-service-web';

export default function BoardCastinfo() {
  /* Service Function */
  const { height, width } = useWindowDimensions();
  const { menu } = useMenu();
  let { id, mode } = useParams();
  console.log(mode);
  const dispatch = useDispatch();
  /* RegEx formatter */
  const [Active, setActive] = useState(1);
  const [validLink1, setValidLink1] = useState(true);
  const [validLink2, setValidLink2] = useState(false);
  const [validLink3, setValidLink3] = useState(false);
  const [validDetail1, setValidDetail1] = useState(true);
  const [validDetail2, setValidDetail2] = useState(true);
  const [validDetail3, setValidDetail3] = useState(true);
  /* Set useState */
  const [imageSave, setImageSave] = useState(null);
  const [imageCarousel, setImageCarousel] = useState(null);
  const [valueConfirm, setValueConfirm] = useState("");
  const [modalIsOpenEdit, setIsOpenEdit] = useState(false);
  // const [isMenu, setIsMenu] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const useStyle = styleSelect();
  let history = useHistory();
  const { addToast } = useToasts();
  const [isModefied, setIsModified] = useState(false);
  const [isBack, setIsBack] = useState(false);

  const options = [
    { label: "เปิดการใช้งาน", value: 1 },
    { label: "ปิดการใช้งาน", value: 0 },
  ];
  /* Method Condition */

  /*ตรวจสอบข้อมูล รหัสผ่านตรงกัน*/

  const OnBack = () => {
    if (isModefied) {
      openModalSubject();
    } else {
      history.push("/admin/boardcast");
    }
  };

  function openModalSubject() {
    setIsOpenEdit(true);
  }

  function closeModalSubject() {
    setIsOpenEdit(false);
  }

  const onEditValue = () => {
    if (isModefied) {
      setIsBack(true);
      formik.handleSubmit();
      setIsOpenEdit(false);
    } else {
      if (!isModefied) history.push("/admin/boardcast");
    }
    // setIsModified(false);
    // history.push("/admin/users");
  };

  const onReturn = () => {
    setIsModified(false);
    history.push("/admin/boardcast");
  };

  /* Form insert value */
  const formik = useFormik({
    initialValues: {
      id: "",
      carouselPicture: "",
      carouselName: "",
      carouselDetail: "",
      linkTitle1: "",
      linkDetail1: "",
      linkTitle2: "",
      linkDetail2: "",
      linkTitle3: "",
      linkDetail3: "",
      isUse: true,
      isActive: true,
    },
    validationSchema: Yup.object({
      carouselPicture: Yup.string().required(
        Storage.GetLanguage() === "th"
          ? "* กรุณาเลือก รูปภาพ Carousel"
          : "* Please select image carousel"
      ),
      carouselName: Yup.string().required(
        Storage.GetLanguage() === "th"
          ? "* กรุณากรอก หัวข้อ"
          : "* Please enter your carousel name"
      ),
      linkTitle1: Yup.string().required(
        Storage.GetLanguage() === "th"
          ? "* กรุณากรอก Link 1"
          : "* Please enter your Link 1"
      ),
      linkDetail1: Yup.string().required(
        Storage.GetLanguage() === "th"
          ? "* กรุณากรอก Url สำหรับ Link ข้อมูลภายนอก"
          : "* Please enter your Link Detail 1"
      ),
    }),
    onSubmit: (values) => {
      if (validDetail1 && validDetail2 && validDetail3) {
        setIsBack(false);

        if (
          formik.values.linkDetail2.length > 0 &&
          formik.values.linkTitle2.length == 0
        ) {
          setValidLink2(true);
        } else if (
          formik.values.linkDetail3.length > 0 &&
          formik.values.linkTitle3.length == 0
        ) {
          setValidLink3(true);
        } else {
          setValidLink2(false);
          setValidLink3(false);
          if (isNew) {
            formik.values.addBy = sessionStorage.getItem("user");
            let formData = new FormData();
            formData.append("file", imageSave);
            formData.append("boardcast", JSON.stringify(values));
            axios.post("boardcast/create", formData).then((res) => {
              if (res.data.status) {
                setIsNew(false);
                addToast(
                  Storage.GetLanguage() === "th"
                    ? "บันทึกข้อมูลสำเร็จ"
                    : "Save data successfully",
                  { appearance: "success", autoDismiss: true }
                );
                formik.values.id = res.data.tbBoardCast.id;
                if (isBack) history.push("/admin/boardcast");
                if (modalIsOpenEdit) history.push("/admin/boardcast");
                else
                  history.push(
                    `/admin/boardcastinfo/${res.data.tbBoardCast.id}/edit`
                  );
              } else {
                setIsOpenEdit(false);
                addToast(
                  Storage.GetLanguage() === "th"
                    ? res.data.message
                    : res.data.message,
                  { appearance: "warning", autoDismiss: true }
                );
              }
            });
          } else {
            formik.values.id = id;
            formik.values.updateBy = sessionStorage.getItem("user");
            let formData = new FormData();
            formData.append("file", imageSave);
            formData.append("boardcast", JSON.stringify(values));
            axios.post("boardcast/update", formData).then((res) => {
              if (res.data.status) {
                setIsModified(false);
                formik.resetForm();
                fetchData();
                setIsOpenEdit(false);

                if (isBack) history.push("/admin/boardcast");
                if (modalIsOpenEdit) history.push("/admin/boardcast");
                addToast(
                  Storage.GetLanguage() === "th"
                    ? "บันทึกข้อมูลสำเร็จ"
                    : "Save data successfully",
                  { appearance: "success", autoDismiss: true }
                );
              } else {
                addToast(
                  Storage.GetLanguage() === "th"
                    ? res.data.message
                    : res.data.message,
                  { appearance: "warning", autoDismiss: true }
                );
              }
            });
          }
        }
      }
    },
  });

  async function fetchData() {
    let response = await axios.post(`/boardcast/byId`, { id: id });
    let boardcast = await response.data.tbBoardCast;
    formik.resetForm();
    setValueConfirm("");
    if (boardcast !== null) {
      for (var columns in response.data.tbBoardCast) {
        formik.setFieldValue(
          columns,
          response.data.tbBoardCast[columns],
          false
        );
        if (columns == "carouselPicture") {
          console.log(
            config._baseURLIMG + "img/" + response.data.tbBoardCast[columns]
          );
          setImageCarousel(
            config._baseURLIMG + "img/" + response.data.tbBoardCast[columns]
          );
        } else if (columns == "isActive") {
          setActive(response.data.tbBoardCast[columns] ? 1 : 0);
        }
      }
      setIsNew(false);
    } else {
      setIsNew(true);
    }
  }

  const handleChangeImage = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (e.target.files[0].size > 1000000) {
        // console.log("ขนาดรูปภาพ จะต้องไม่เกิน 1 mb");
        addToast("ขนาดรูปภาพ จะต้องไม่เกิน 1 mb", {
          appearance: "warning",
          autoDismiss: true,
        });
      } else {
        const dataImage = ValidateService.validateImage(e.target.files[0].name);
        if (!dataImage) {
          dispatch(fetchLoading());
          const base64 = await FilesService.convertToBase64(e.target.files[0]);
          setImageSave(e.target.files[0]);
          formik.setFieldValue("carouselPicture", e.target.files[0].name);
          // const index = parseInt(e.target.id.replace("file", ""));
          setImageCarousel(base64);
          // setStockImage(
          //   stockImage.map((x, i) => {
          //     if (i + 1 === index) {
          //       return { ...x, image: base64 };
          //     }
          //     return x;
          //   })
          // );
          dispatch(fetchSuccess());
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-warp">
        <span className="text-base font-bold margin-auto-t-b">
          <i className="fas fa-user-friends"></i>&nbsp;
        </span>
        <span className="text-base margin-auto font-bold">จัดการ Carousel</span>
      </div>
      <div className="w-full">
        <form>
          <div className="w-full">
            <div className="flex justify-between py-2 mt-4">
              <span className="text-lg  text-green-mbk margin-auto font-bold">
                เพิ่ม / แก้ไข จัดการ Carousel
              </span>
              <div
                className={
                  "margin-auto-t-b" + (width < 1024 ? " hidden" : " block")
                }
              >
                <div className="w-full px-4">
                  <div className="relative w-full text-right">
                    <button
                      className="bg-rose-mbk text-white active:bg-rose-mbk font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        OnBack();
                      }}
                    >
                      ย้อนกลับ
                    </button>
                    {mode == "edit" && (
                      <button
                        className={
                          "bg-gold-mbk text-white active:bg-gold-mbk font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        }
                        type="button"
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                      >
                        บันทึกข้อมูล
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  "margin-auto-t-b" + (width < 1024 ? " block" : " hidden")
                }
              >
                <button
                  // data-dropdown-toggle="dropdownmenu"
                  className="flex items-center py-4 px-2 w-full text-base font-normal bg-transparent outline-none button-focus"
                  type="button"
                  // onClick={() => ClickMenu()}
                >
                  <i
                    className="fas fa-bars"
                    id={menu ? "dropdownDefaults" : "dropdownDefault"}
                  ></i>
                </button>
                <div
                  id="dropdownmenu"
                  className={
                    "z-10 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 buttonInfo" +
                    (menu ? " block absolute isMenu" : " hidden")
                  }
                >
                  <ul
                    className="py-1 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefault"
                  >
                    <li>
                      <div className="flex flex-wrap" id="save">
                        <span
                          id="save"
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white font-bold text-sm w-8/12"
                        >
                          <i className="fas fa-save mr-2"></i>
                          บันทึก
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="flex flex-wrap" id="back">
                        <span
                          onClick={() => {
                            OnBack();
                          }}
                          id="back"
                          className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white font-bold text-sm w-8/12"
                        >
                          <i className="fas fa-arrow-left mr-2"></i>
                          ย้อนกลับ
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border bg-white rounded-lg Overflow-info">
              <div className="flex-auto lg:px-10 py-10">
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-2/12 px-4  ">
                    <div className="relative w-full">
                      <label
                        className="text-blueGray-600 text-sm font-bold"
                        htmlFor="grid-password"
                      >
                        รูปภาพ
                      </label>
                      <span className="text-sm ml-2 text-red-500">*</span>
                    </div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4 margin-auto-t-b">
                    <div className="relative w-full">
                      <ProfilePictureUC
                        id={id}
                        isEdit={mode == "edit"}
                        hoverText="เลือกรูป Carousel"
                        src={imageCarousel}
                        onChange={handleChangeImage}
                      />
                    </div>
                  </div>
                  {formik.touched.carouselPicture &&
                    formik.errors.carouselPicture && (
                      <>
                        <div className="w-full lg:w-2/12 px-4 mb-2">
                          <div className="relative w-full"></div>
                        </div>
                        <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                          <div
                            className="relative w-full"
                            style={{ color: "#ccc" }}
                          >
                            {formik.touched.carouselPicture &&
                            formik.errors.carouselPicture ? (
                              <div className="text-sm text-red-500">
                                {formik.errors.carouselPicture}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                  <div className="w-full lg:w-2/12 px-4 mb-2 mt-4">
                    <label
                      className="text-blueGray-600 text-sm font-bold mb-2"
                      htmlFor="grid-password"
                    ></label>
                  </div>
                  <div
                    className={
                      "w-full lg:w-8/12 px-4 " +
                      (width < 1024 ? " " : "  mb-4 mt-4")
                    }
                  >
                    <Radio.Group
                      disabled={mode == "view"}
                      options={options}
                      onChange={(e) => {
                        setIsModified(true);
                        setActive(e.target.value);
                        formik.setFieldValue("isActive", e.target.value);
                      }}
                      value={Active}
                    />
                  </div>
                  <div className={"w-full lg:w-2/12 px-4 "}>
                    <div className="relative w-full ">
                      <label
                        className="text-blueGray-600 text-sm font-bold"
                        htmlFor="grid-password"
                      >
                        หัวข้อ
                      </label>
                      <span className="text-sm ml-2 text-red-500">*</span>
                    </div>
                  </div>
                  <div className={"w-full lg:w-8/12 px-4 margin-auto-t-b "}>
                    <div className="relative w-full">
                      {mode == "edit" ? (
                        <input
                          type="text"
                          className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="carouselName"
                          name="carouselName"
                          maxLength={100}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setIsModified(true);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.carouselName}
                          autoComplete="carouselName"
                        />
                      ) : (
                        <span>{formik.values.carouselName}</span>
                      )}
                    </div>
                  </div>

                  <div className="w-full lg:w-2/12 px-4 mb-2">
                    <div className="relative w-full"></div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4 mb-2 mt-2 font-xs">
                    {mode == "edit" && (
                      <div
                        className="relative w-full"
                        style={{ color: "#ccc" }}
                      >
                        สูงสุด 40 ตัวอังษร
                      </div>
                    )}
                  </div>

                  {formik.touched.carouselName &&
                    formik.errors.carouselName && (
                      <>
                        <div className="w-full lg:w-2/12 px-4 mb-2">
                          <div className="relative w-full"></div>
                        </div>
                        <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                          <div
                            className="relative w-full"
                            style={{ color: "#ccc" }}
                          >
                            {formik.touched.carouselName &&
                            formik.errors.carouselName ? (
                              <div className="text-sm text-red-500">
                                {formik.errors.carouselName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                  {/* {formik.touched.carouselName &&
                    formik.errors.carouselName && (
                      <>
                        <div className={"w-full lg:w-2/12"}>
                          <div className="relative w-full"></div>
                        </div>
                        <div
                          className={"w-full lg:w-8/12 margin-auto-t-b"}
                        >
                          <div className="relative w-full">
                            {formik.touched.carouselName &&
                            formik.errors.carouselName ? (
                              <div className="text-sm py-2 px-2 text-red-500">
                                {formik.errors.carouselName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )} */}

                  <div className={"w-full lg:w-2/12 px-4"}>
                    <div className="relative w-full ">
                      <label
                        className="text-blueGray-600 text-sm font-bold"
                        htmlFor="grid-password"
                      >
                        รายละเอียด
                      </label>
                    </div>
                  </div>
                  <div className={"w-full lg:w-8/12 px-4 margin-auto-t-b "}>
                    <div className="relative w-full">
                      {mode == "edit" && (
                        <textarea
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          rows="5"
                          id="carouselDetail"
                          name="carouselDetail"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.carouselDetail}
                          autoComplete="new-password"
                          maxLength={300}
                        ></textarea>
                      )}
                    </div>
                  </div>
                  <div className="w-full lg:w-2/12 px-4 mb-2 ">
                    <div className="relative w-full"></div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                    {mode == "edit" && (
                      <div
                        className="relative w-full"
                        style={{ color: "#ccc" }}
                      >
                        สูงสุด 300 ตัวอังษร
                      </div>
                    )}
                  </div>
                  <div className={"w-full lg:w-2/12 px-4 "}>
                    <div className="relative w-full ">
                      <label
                        className="text-blueGray-600 text-sm font-bold"
                        htmlFor="grid-password"
                      >
                        Link 1
                      </label>
                      <span className="text-sm ml-2 text-red-500">*</span>
                    </div>
                  </div>
                  <div className={"w-full lg:w-8/12 px-4 margin-auto-t-b "}>
                    <div className="relative w-full flex flex-wrap mb-1">
                      <div className="w-full lg:w-6/12">
                        <div className="relative w-full">
                          {mode == "edit" ? (
                            <input
                              type="text"
                              className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="linkTitle1"
                              name="linkTitle1"
                              placeholder="ชื่อ Link 1"
                              maxLength={20}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setIsModified(true);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.linkTitle1}
                              autoComplete="linkTitle1"
                            />
                          ) : (
                            <span>{formik.values.linkTitle1}</span>
                          )}
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 pl-4">
                        <div className="relative w-full">
                          {mode == "edit" ? (
                            <input
                              type="text"
                              className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="linkDetail1"
                              name="linkDetail1"
                              maxLength={300}
                              placeholder="Link 1"
                              onChange={(e) => {
                                formik.handleChange(e);
                                if (e.target.value == "") setValidDetail1(true);
                                else
                                  setValidDetail1(
                                    validator.isURL(e.target.value)
                                  );
                                setIsModified(true);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.linkDetail1}
                              autoComplete="linkDetail1"
                            />
                          ) : (
                            <span>{formik.values.linkDetail1}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-2/12 px-4 mb-2 ">
                    <div className="relative w-full"></div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                    {mode == "edit" && (
                      <div
                        className="relative w-full"
                        style={{ color: "#ccc" }}
                      >
                        สูงสุด 20 ตัวอังษร
                      </div>
                    )}
                  </div>

                  {!validDetail1 && (
                    <>
                      <div className="w-full lg:w-2/12 px-4 mb-2">
                        <div className="relative w-full"></div>
                      </div>
                      <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                        <div
                          className="relative w-full"
                          style={{ color: "#ccc" }}
                        >
                          <div className="text-sm text-red-500">
                            {"* รูปแบบ URL ไม่ถูกต้องกรุณาระบุ URL ใหม่"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* {formik.touched.linkTitle1 && formik.errors.linkTitle1 && (
                    <>
                      <div className="w-full lg:w-2/12 px-4 mb-2">
                        <div className="relative w-full"></div>
                      </div>
                      <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                        <div
                          className="relative w-full"
                          style={{ color: "#ccc" }}
                        >
                          <div className="text-sm text-red-500">
                            {formik.errors.linkTitle1}
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}
                  {((formik.touched.linkTitle1 && formik.errors.linkTitle1) ||
                    (formik.errors.linkDetail1 &&
                      formik.touched.linkDetail1)) && (
                    <>
                      <div className="w-full lg:w-2/12 px-4 mb-2">
                        <div className="relative w-full"></div>
                      </div>
                      <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                        <div
                          className="relative w-full"
                          style={{ color: "#ccc" }}
                        >
                          <div className="relative w-full flex flex-wrap mb-1">
                            <div className="w-full lg:w-6/12">
                              {formik.touched.linkTitle1 &&
                                formik.errors.linkTitle1 && (
                                  <div className="text-sm text-red-500">
                                    {formik.errors.linkTitle1}
                                  </div>
                                )}
                            </div>
                            <div className="w-full lg:w-6/12">
                              {formik.touched.linkDetail1 &&
                                formik.errors.linkDetail1 && (
                                  <div
                                    className="text-sm text-red-500"
                                    style={{ paddingLeft: "16px" }}
                                  >
                                    {formik.errors.linkDetail1}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className={"w-full lg:w-2/12 px-4"}>
                    <div className="relative w-full ">
                      <label
                        className="text-blueGray-600 text-sm font-bold"
                        htmlFor="grid-password"
                      >
                        Link 2
                      </label>
                    </div>
                  </div>
                  <div className={"w-full lg:w-8/12 px-4 margin-auto-t-b "}>
                    <div className="relative w-full flex flex-wrap">
                      <div className="w-full lg:w-6/12">
                        <div className="relative w-full">
                          {mode == "edit" ? (
                            <input
                              type="text"
                              className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="linkTitle2"
                              name="linkTitle2"
                              placeholder="ชื่อ Link 2"
                              maxLength={20}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setIsModified(true);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.linkTitle2}
                              autoComplete="linkTitle2"
                            />
                          ) : (
                            <span>{formik.values.linkTitle2}</span>
                          )}
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 pl-4">
                        <div className="relative w-full">
                          {mode == "edit" ? (
                            <input
                              type="text"
                              className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="linkDetail2"
                              name="linkDetail2"
                              placeholder="Link 2"
                              maxLength={100}
                              onChange={(e) => {
                                formik.handleChange(e);
                                if (e.target.value == "") setValidDetail2(true);
                                else
                                  setValidDetail2(
                                    validator.isURL(e.target.value)
                                  );
                                setIsModified(true);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.linkDetail2}
                              autoComplete="linkDetail2"
                            />
                          ) : (
                            <span>{formik.values.linkDetail2}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-2/12 px-4 mb-2 ">
                    <div className="relative w-full"></div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4 mb-2 mt-1 font-xs">
                    {mode == "edit" && (
                      <div
                        className="relative w-full"
                        style={{ color: "#ccc" }}
                      >
                        สูงสุด 20 ตัวอังษร
                      </div>
                    )}
                  </div>
                  {!validDetail2 && (
                    <>
                      <div className="w-full lg:w-2/12 px-4 mb-2">
                        <div className="relative w-full"></div>
                      </div>
                      <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                        <div
                          className="relative w-full"
                          style={{ color: "#ccc" }}
                        >
                          <div className="text-sm text-red-500">
                            {"* รูปแบบ URL ไม่ถูกต้องกรุณาระบุ URL ใหม่"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {validLink2 && (
                    <>
                      <div className="w-full lg:w-2/12 px-4 mb-2">
                        <div className="relative w-full"></div>
                      </div>
                      <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                        <div
                          className="relative w-full"
                          style={{ color: "#ccc" }}
                        >
                          <div className="text-sm text-red-500">
                            {
                              "* Link 2 ไม่สามารถเป็นค่าว่าง กรณีมีการกรอกรายละเอียด Link 2"
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className={"w-full lg:w-2/12 px-4 "}>
                    <div className="relative w-full ">
                      <label
                        className="text-blueGray-600 text-sm font-bold"
                        htmlFor="grid-password"
                      >
                        Link 3
                      </label>
                    </div>
                  </div>
                  <div className={"w-full lg:w-8/12 px-4 margin-auto-t-b "}>
                    <div className="relative w-full flex flex-wrap">
                      <div className="w-full lg:w-6/12">
                        <div className="relative w-full">
                          {mode == "edit" ? (
                            <input
                              type="text"
                              className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="linkTitle3"
                              name="linkTitle3"
                              placeholder="ชื่อ Link 3"
                              maxLength={20}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setIsModified(true);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.linkTitle3}
                              autoComplete="linkTitle3"
                            />
                          ) : (
                            <span>{formik.values.linkTitle3}</span>
                          )}
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 pl-4">
                        <div className="relative w-full">
                          {mode == "edit" ? (
                            <input
                              type="text"
                              className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="linkDetail3"
                              name="linkDetail3"
                              maxLength={300}
                              placeholder="Link 3"
                              onChange={(e) => {
                                formik.handleChange(e);
                                if (e.target.value == "") setValidDetail3(true);
                                else
                                  setValidDetail3(
                                    validator.isURL(e.target.value)
                                  );
                                setIsModified(true);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.linkDetail3}
                              autoComplete="linkDetail3"
                            />
                          ) : (
                            <span>{formik.values.linkDetail3}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-2/12 px-4 mb-2 ">
                    <div className="relative w-full"></div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4 mb-2  mt-1  font-xs">
                    {mode == "edit" && (
                      <div
                        className="relative w-full"
                        style={{ color: "#ccc" }}
                      >
                        สูงสุด 20 ตัวอังษร
                      </div>
                    )}
                  </div>
                  {!validDetail3 && (
                    <>
                      <div className="w-full lg:w-2/12 px-4 mb-2">
                        <div className="relative w-full"></div>
                      </div>
                      <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                        <div
                          className="relative w-full"
                          style={{ color: "#ccc" }}
                        >
                          <div className="text-sm text-red-500">
                            {"* รูปแบบ URL ไม่ถูกต้องกรุณาระบุ URL ใหม่"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {validLink3 && (
                    <>
                      <div className="w-full lg:w-2/12 px-4 mb-2">
                        <div className="relative w-full"></div>
                      </div>
                      <div className="w-full lg:w-8/12 px-4 mb-2 font-xs">
                        <div
                          className="relative w-full"
                          style={{ color: "#ccc" }}
                        >
                          <div className="text-sm text-red-500">
                            {
                              "* Link 3 ไม่สามารถเป็นค่าว่าง กรณีมีการกรอกรายละเอียด Link 3"
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="w-full lg:w-2/12 px-4 mt-2 margin-auto-t-b">
                    <div className="relative w-full"></div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4 margin-auto-t-b">
                    <div className="relative w-full mt-1">
                      ลำดับการแสดงผล {id}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ConfirmEdit
        showModal={modalIsOpenEdit}
        message={"ผู้ดูแลระบบ"}
        hideModal={() => {
          closeModalSubject();
        }}
        confirmModal={() => {
          onEditValue();
        }}
        returnModal={() => {
          onReturn();
        }}
      />
    </>
  );
}
