const config = {
  dev:  false,
  // UID: "Ubfcc855b6a6dae691da898801d1ec206",
  UID: "Udf45f7ebfc9d9070dc73ee75cd7a1421",
  // liffId: "1657109260-L0jrqxlN", //whale
  // liffId: "1657238460-gz9m6Wkn", //undefined

  //Mahboonkrongrice
  liffId: "1657244713-OZvRKpPj",
  liffShop: "1657244713-X5GJNM3P",
  liffPoint: "1657244713-9L71Dxno",
  liffReward: "1657244713-3bqVRZNk",

  // otp
  api_key: "6530872881f77ab91c0eff3e92be3dcb",
  secret_key: "ec1gCIQiR3CNwgtb",
  project_key: "7e4e202a30",
  otpsend: "https://portal-otp.smsmkt.com/api/otp-send",
  otpvalidate: "https://portal-otp.smsmkt.com/api/otp-validate",


//  _baseURL: "http://localhost:3001/mbkserver/",
  // _baseURLImport: "http://localhost:89/mbkimport/",

  // _baseURL: "https://rewards.mahboonkrongrice.com/mahboonkrongserver/",
  // _baseURLImport: "https://rewards.mahboonkrongrice.com/mahboonkrongimport/",

  _baseURL: "https://rewards.mahboonkrongrice.com/mbkserver/",
  _baseURLImport: "https://rewards.mahboonkrongrice.com/mbkimport/",

  forgotpassword: "https://rewards.mahboonkrongrice.com",
  // forgotpassword: "https://mbk.hopeagro.co.th"
};
export default config;
// baseURL: `https://rewards.mahboonkrongrice.com/mbkserver/`,
// baseURL: `https://rewards.mahboonkrongrice.com/mahboonkrongserver/`,
// baseURL: `https://hopeagro.co.th/mahboonkrongserver/`,
