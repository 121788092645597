import React from "react";

// components

export default function empty() {
  return (
    <>
      
    </>
  );
}
