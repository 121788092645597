// common
export const FETCH_LOADING = 'fetch_loading';
export const FETCH_SUCCESS = 'fetch_success';

// shop setting
export const SET_CURRENT_SHOPSETTING = 'set_current_shopsetting';

//auth
export const LOGOUT = "LOGOUT";

//auth
export const BACKPAGE = "BACKPAGE";