
import config from './helpers';

//const urlPath = "http://rewards.mahboonkrongrice.com:86"/*deploy*/
//const urlPath = "https://mju-project.herokuapp.com"/*heroku*/
//const urlForgot = "http://rewards.mahboonkrongrice.com"/*testing*/
const urlForgot = config.forgotpassword;/*testing*/
// const urlForgot = "https://rewards.mahboonkrongrice.com"

export default urlForgot;
